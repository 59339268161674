<template>
  <div>
    <template v-if="user && hasInvoices && loading === false">
      <div class="relative flex flex-col rounded-lg bg-green-50 px-4 py-10 lg:flex-row">
        <section class="flex flex-col lg:basis-1/2 lg:px-4">
          <h2>Wir haben für dich nachgerechnet</h2>
          <p>
            Mit dem haelsi Abo hättest du bereits
            <strong class="font-bold">{{ transformToCurrency(savings) }}</strong> gespart.
          </p>
          <p class="mt-4">
            Abo Mitglieder sparen 25% auf alle Arzttermine und 30% auf Therapietermine, wie
            Physiotherapie, Osteopathie und Mental&nbsp;Health. So soll Gesundheit noch günstiger
            werden.
          </p>
          <AppButton
            class="mt-4 self-start lg:!w-auto"
            variant="secondary"
            to="https://haelsi.at/abo?mtm_campaign=Portal-MemberSavings&mtm_source=Portal"
            >Mehr zum Abo
          </AppButton>
        </section>
        <div class="z-10 mt-8 py-4 lg:mt-0 lg:basis-1/2 lg:px-8">
          <LoadingSpinner v-if="loading" />
          <div v-else class="mx-auto flex w-72 flex-col gap-3">
            <p class="font-medium">Deine bisherigen Termine</p>
            <div class="flex justify-between">
              <p class="text-sm">ohne Abo</p>
              <p class="px-4 font-medium">{{ expenses ? transformToCurrency(expenses) : 0 }}</p>
            </div>
            <div class="flex justify-between">
              <p class="text-sm">mit Abo</p>
              <p class="px-4 font-medium">
                {{ expensesWithMembership ? transformToCurrency(expensesWithMembership) : 0 }}
              </p>
            </div>
            <div class="flex justify-between">
              <p class="pt-2 font-medium">Deine Ersparnis &#127881;</p>
              <p class="border-t-2 border-solid border-black px-4 pt-2 font-medium">
                {{ transformToCurrency(savings) }}
              </p>
            </div>
          </div>
        </div>
        <CloseButton class="absolute right-4 top-4" @click="handleClose" />
        <SvgIcon name="blob" class="absolute bottom-0 right-0 z-0" />
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { computed, ref, watch } from 'vue';

import AppButton from '@/components/AppButton.vue';
import CloseButton from '@/components/CloseButton.vue';
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import SvgIcon from '@/components/SvgIcon.vue';
import { useFetch } from '@/composables/fetch';
import { useCustomToast } from '@/composables/useCustomToast';
import { GeneralErrors } from '@/constants/errors/general.error';
import { invoiceService } from '@/services';
import { useUserPreferencesStore } from '@/stores/user-preferences.store';
import { useUserStore } from '@/stores/user.store';
import { transformToCurrency } from '@/utils/transformToCurrency';

const userStore = useUserStore();
const userPreferencesStore = useUserPreferencesStore();
userPreferencesStore.fetchPreferences();
const { user } = storeToRefs(userStore);

const invoicesRef = ref(invoiceService.getAllCompleted());
const { loading, data: completedInvoices, error } = useFetch(invoicesRef);

const { showErrorToast } = useCustomToast();
watch(error, () => {
  if (error.value) {
    showErrorToast({ text: GeneralErrors.default });
  }
});

const hasInvoices = computed(() => completedInvoices.value?.meta.total !== 0);

const expenses = computed(() =>
  completedInvoices.value?.data.reduce(
    (accumulator, invoice) => accumulator + invoice.finalGrossPrice,
    0,
  ),
);
const expensesWithMembership = computed(() => (expenses.value ? expenses.value * 0.75 : 0));
const savings = computed(() =>
  expenses.value ? expenses.value - expensesWithMembership.value : 0,
);

function handleClose(): void {
  userPreferencesStore.closeMembershipUpsellBox();
}
</script>

<style scoped></style>

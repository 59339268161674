import * as Sentry from '@sentry/vue';
import type { App } from 'vue';
import type { Router } from 'vue-router';
const apiBaseUrl = import.meta.env.VITE_API_BASE_URL;
const appBaseUrl = import.meta.env.VITE_APP_BASE_URL;
const tracesSampleRate = import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE;
const dsn = import.meta.env.VITE_SENTRY_VUE_DSN;
const environment = import.meta.env.MODE;
const replaysSessionSampleRate = import.meta.env.VITE_SENTRY_SESSION_SAMPLE_RATE;
const replaysOnErrorSampleRate = import.meta.env.VITE_SENTRY_SESSION_ERROR_SAMPLE_RATE;

export function initializeSentry(app: App<Element>, router: Router): void {
  if (dsn === '') {
    return;
  }

  Sentry.init({
    app,
    dsn,
    integrations: [Sentry.replayIntegration(), Sentry.browserTracingIntegration({ router })],
    tracePropagationTargets: ['localhost', apiBaseUrl, appBaseUrl, /^\//],
    tracesSampleRate,
    environment,
    replaysSessionSampleRate,
    replaysOnErrorSampleRate,
  });
}

import { MessengerState } from '@userlike/messenger-internal/dist/es/shared/types';

export async function maximizeChat(): Promise<void> {
  const userLike = window.haelsiUserlike;

  if (!userLike || userLike.getState().state === MessengerState.Maximized) {
    return;
  }

  await userLike.maximize();
}
